.team-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Tan Nimbus';
    height: 140vh;
    width: 100vw;
    z-index: 1;
    margin-top: 2vh;
}
.team-page .bg-top {
    position: fixed;
    top: 0;
    left: 0;
    height: 40vh;
    width: 54vw;
    background-color: var(--brown);
    z-index: 0;
    border-bottom-right-radius: 100%;

}
.team-page .bg-bottom {
    position: fixed;
    bottom: 0;
    height: 80vh;
    width: 100vw;
    background-color: var(--brown);
    z-index: 0;
    border-top-left-radius: 100%;
}
.team-page h2 {
  font-size: 3.5vh;
  margin-bottom: 3vh;
}
.team-members-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 75vw;
    border: solid;
    border-style: double;
    border-width: 7px;
    border-radius: 20px;
    padding: 20px;
}
.team-member-card {
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 25vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
}
.team-member-photo-container {
    width: 100%;
    font-size: 2.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.team-member-photo {
    height: 35vh;
    width: 35vh;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
}
.team-member-blurb {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    font-family: 'Abhaya Libre', serif;
    color: black;
}

@media only screen and (max-width: 1050px) {
  .team-page {
    height: 220vh;
    margin-top: 5vh;

  }
  .team-page h2 {
    font-size: 4.5vh;
  }
  .team-members-container {
    width: 90vw;
    padding: 10px;
    flex-direction: column;
  }
  .team-member-card {
    height: 40vh;
    width: 40vw;
  }
  .team-member-photo-container {
    font-size: 3.5vh;
    line-height: 2.5vh;
  }
  .team-member-photo {
    height: 30vh;
    width: 30vh;
  }
  .team-member-blurb {
    font-size: 15px;
    width: 70vw;
    height: 35vh;
  }

}

@media only screen and (max-width: 780px) {
  .team-page h2 {
    font-size: 3.5vh;
  }
  .team-page {
    margin-top: 2vh;
    height: 320vh;
  }
  .team-members-container {
    height: 260vh;
  }
  .team-member-photo-container {
    font-size: 3vh;
    margin: 2vh;
  }
  .team-member-photo {
    height: 38vh;
    width: 35vw;
  }
  .team-member-blurb {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .team-page {
    height: 100%;
  }
  .team-page h2 {
    font-size: 3vh;
    margin-top: 1vh;
  }
  .team-members-container {
    height: 210vh;
  }
  .team-member-photo-container {
    font-size: 2vh;
    width: 50vw;
  }
  .team-member-photo {
    height: 30vh;
    width: 30vh;
  }
  .team-member-blurb {
    font-size: 13px;
  }

}





  

