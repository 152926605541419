@import url('https://fonts.cdnfonts.com/css/bright-peony-personal-use');
@import url('https://fonts.cdnfonts.com/css/mercado-2');
@import url('https://fonts.cdnfonts.com/css/benathio');


.home-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    background-color: var(--brown);
}
.home-page .nav-btn {
    color: black;
}
.home-page .nav-btn:hover {
    color: var(--darkPink);
}
.home-tagline {
    font-family: 'Abhaya Libre', serif;
    position: absolute;
    right: 4vw;
    top: 0;
    width: 13vw;
    text-align: right;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;

}
.home-tagline .every-body {
    font-size: 14px;
    line-height: 18px;
    font-weight: lighter;
    font-family: 'Tan Nimbus', serif;

}
.home-page h1 {
    font-family: 'Tan Nimbus', serif;
    font-size: 17vh;
    line-height: 18vh;
    color: var(--darkPink);
    z-index: 1;
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-end;
    position: absolute;
    bottom: 7vh;
    font-weight: 900;
}
.drawing {
    height: 95vh;
    width: 80vw;
    z-index: 1;
    background-image: url("https://i.imgur.com/x3K0ZcT.png");
    background-position: center;
    background-size: cover;
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 1050px) {
    .home-tagline {
        font-size: 17px;
        width: 18vw;
        line-height: 17px;
    }
    .home-tagline .every-body {
        font-size: 12px;
        line-height: 15px;
    }
    .drawing {
        height: 90vh;
        bottom: 5vh;
    }
    .home-page h1 {
        font-size: 15vh;
        line-height: 18vh;
        bottom: 7vh;
        width: 80vw;
    }
    .fixed-links-container {
        right: 2vw;
    }
    .home-page .fixed-links-container a, .nav .fixed-links-container a {
        font-size: 5vh;
    }
}

@media only screen and (max-width: 780px) {
    .home-tagline {
        font-size: 17px;
        width: 25vw;
        line-height: 17px;
    }
}

@media only screen and (max-width: 480px) {
    .home-tagline {
        font-size: 16px;
        right: 5vw;
        top: 0;
    }
    .home-tagline .every-body {
        font-size: 12px;
        line-height: 13px;
    }
    .drawing {
        height: 73vh;
        bottom: 5vh; 
        background-size: cover;
        width: 100vw;
    }
    .home-page h1 {
        font-size: 11.8vh;
        bottom: 7vh;
        width: 100vw;
        line-height: 15vh;
    }
    .home-page .fixed-links-container {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 6vh;
        flex-direction: row;
        width: 100vw;
        justify-content: space-evenly;
        align-items: center;
    }
}

