.contact-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Tan Nimbus';
    color: var(--brown);
}
.contact-page h2{
    margin-top: 5vh;
    font-size: 5vh;
}
.contact-main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 60vh;
    width: 100vw;
}
.contact-link-container a {
    color: var(--brown);
    font-size: 7.5vh;
    margin: 0;
}
.contact-link-container a:hover {
    color: var(--yellowBrown);
}
.contact-link-container p {
    font-size: 15px;
    margin: 0;
}

@media only screen and (max-width: 480px) {
    .contact-page h2 {
        font-size: 4vh;
    }
    .contact-link-container p {
        font-weight: bold;
    }
}