@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

.about-page {
  height: 300vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Tan Nimbus';
  z-index: 0;
  color: var(--brown);
}
.our-mission-container {
  margin-top: 6vh;
  width: 55%;
  border: solid;
  border-style: double;
  border-width: 7px;
  border-top-right-radius: 100%;
  border-top-left-radius: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
}
.our-mission-container h3 {
  font-family: 'Abhaya Libre', serif;
  font-size: 4vh;
  text-align: center;
}
.about-page .love-respect-care, .about-page .every-body {
  font-size: 3vh;
  line-height: 5vh;
  font-family: 'Tan Nimbus';
  font-weight: lighter;
}
.about-page h2 {
  font-size: 3.5vh;
  margin-top: 4vh;
  margin-bottom: 1vh;
}
.about-us-pic {
  height: 55vh;
  width: 60%;
  background-image: url('https://i.imgur.com/6pOIVQ7.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
.our-story-container {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  height: 130vh;
  font-family: 'Abhaya Libre', serif;
  text-align: left;
}
.our-story-container p {
  width: 85%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

@media only screen and (min-width: 1700px) {
  .our-story-container {
    height: 100vh;
  }
}

@media only screen and (max-width: 1050px) {
  .about-page {
    height: 400vh;
  }
  .our-mission-container h3 {
    font-size: 4vh;
  }
  .our-mission-container {
    margin-bottom: 0;
    width: 67vw;
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  .about-page .every-body {
    font-size: 3vh;
  }
  .about-page .love-respect-care {
    font-size: 3vh;
  }
  .about-page h2 {
    font-size: 4.5vh;
    margin-top: 2vh;
    margin-bottom: 0;
    line-height: 4vh;
  }
  .our-story-container {
    width: 95vw;
    font-size: 17px;
  }
  .our-story-container p {
    width: 85%;
  }

}
@media only screen and (max-width: 780px) {
  .about-page {
    height: 460vh;
  }
  .our-mission-container {
    padding-top: 23px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top:5vh;
    width: 65vw;
  }
  .our-mission-container h3 {
    font-size: 3.5vh;
  }
  .about-page h2 {
    font-size: 3.5vh;
    margin-top: 5vh;
  }
  .about-page .love-respect-care, .about-page .every-body {
    font-size: 2.5vh;
    line-height: 3.5vh;
  }
  .about-us-pic {
    width: 75vw;
    height: 45vh;
  }
  .our-story-container {
    width: 100vw;
    height: 135vh;
    margin-top: 2vh;
    margin-bottom: 0;
  }
  .our-story-container p {
    font-size: 15.5px;
    width: 80%;
  }

}

@media only screen and (max-width: 480px) {
  .about-page {
    height: 370vh;
    /* height: 100%; */
  }
  .our-mission-container h3 {
    font-size: 1.9vh;
  }
  .our-mission-container {
    margin-bottom: 0;
    margin-top: 6vh;
    width: 90vw;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .about-page .love-respect-care, .about-page .every-body {
    font-size: 1.5vh;
    line-height: 1.5vh;
  }
  .about-page h2 {
    font-size: 3vh;
    margin-top: 5vh;
  }
  .about-us-pic {
    width: 75vw;
  }
  .our-story-container {
    font-size: 14px;
    width: 100vw;
    height: 130vh;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
  .our-story-container p {
    font-size: 13.5px;
    width: 90%;
  }
  .about-page .nav {
    z-index: 3;
  }

}



  