.retreats-page {
    height: 130vh;
    width: 100vw;
    font-family: 'Abhaya Libre', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    color: var(--brown);
}
.retreats-page h1 {
    font-family: 'Tan Nimbus';
    margin-bottom: 0;
}
.retreats-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.info-buttons-container {
    margin-left: 3vw;
    width: 95%;
    display: flex;
}
.info-btn {
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-bottom: hidden;
    font-family: 'Abhaya Libre', serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    height: 4.5vh;
    padding: 7px;
    padding-top: 4px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--brown);
    color: antiquewhite;
    border-color: var(--brown);
}
.info-btn:hover {
    border-color: antiquewhite;
}
.register-btn {
    width: 30%;
    margin-top: 5px;
    margin-bottom: 4vh;
    background-color: rgba(0, 0, 0, 0);
    border: solid;
    border-radius: 15px;
    border-width: 7px;
    border-style: double;
    color: var(--brown);
    font-family: 'Tan Nimbus';
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
}
.register-btn:hover {
    background-color: var(--brown);
    border-color: var(--brown);
    color: var(--darkPink);
}
.info-display {
    border: solid;
    border-style: double;
    border-width: 7px;
    border-radius: 20px;
    border-color: var(--brown);
    height: 80vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-section {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8%;
}
.info-section h2 {
    font-family: 'Tan Nimbus';
    text-decoration: underline;
    text-decoration-style: double;
    margin-top: 0;
}
.info-section p {
    font-weight: bold;
    font-size: 17px;
}
.location-section iframe {
    height: 35vh;
    width: 30vw;
}
.location-section a {
    color: var(--brown);
    font-weight: bold;
}
.speakers-btns-container {
    width: 100%;
    height: 5vh;
    margin-top: 0;
    margin-bottom: 5%;
    border: solid;
    border-radius: 10px;
}
.speakers-section {
    padding-top: 0;
}
.speakers-btn {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 15%;
    color: var(--brown);
    width: 50%;
    height: 100%;
    margin: 0;
    font-family: 'Abhaya Libre', serif;
    font-weight: bold;
}
.speakers-section span {
    font-family: 'Tan Nimbus';
    text-decoration: underline;
    font-weight: bold;
}
.speakers-section p {
    text-align: left;
    width: 65%;
}

@media only screen and (max-width: 480px) {
    .retreats-page {
        height: 120vh;
    }
    .info-display {
        height: 65vh;
    }
    .location-section iframe {
        height: 30vh;
        width: 60vw;
    }
    .info-buttons-container {
        margin-left: 5vw;
    }
    .info-btn {
        height: 4vh;
    }
    .register-btn {
        width: 70%;
        border-width: 5.5px;
    }
    .info-btn:hover {
        border-width: 2px;
    }
    .speakers-section p {
        width: 90%;
    }
}






