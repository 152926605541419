.resources-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    font-family: 'Tan Nimbus';
    color: var(--brown);
    padding-left: 20vw;
}
.resources-page h2{
    font-family: 'Tan Nimbus';
    color: var(--brown);
    padding-top: 4vh;
    font-size: 4vh;
    position: absolute center;
}
.resource-link {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.resource-link a {
    color: var(--brown);
    font-family: 'Abhaya Libre', serif;
    font-size: 16px;
    margin-top: 0px;
}
.resource-link a:hover {
    font-weight: bold;
}
.resource-link p:empty {
    display: none;
}

.resource-link .description {
    font-family: 'Abhaya Libre', serif;
    color: var(--brown);
    font-size: 14px;
    margin-top: 0px;
}
