/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.cdnfonts.com/css/belagio-font');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');


@font-face {
  font-family: "Tan Nimbus";
  src: url("./fonts/TAN-NIMBUS.ttf") format("truetype");
}

:root {
  --litePink: rgb(255, 214, 226);
  --medPink: rgb(255, 207, 233);
  --darkPink: rgb(235, 168, 221);
  --litePurple: rgb(175, 154, 197);
  --medPurple: rgb(173, 144, 198);
  --darkPurple: rgb(93, 75, 125);
  --brick: rgb(158, 60, 50);
  --brown: rgb(191, 120, 56);
  --yellowBrown: rgb(207, 133, 34);
}
body {
  background-color: antiquewhite;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100vw;
  background-color: var(--brick);
  z-index: 3;
}
.nav-links-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15vh;
}
.nav-btn {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  height: 9vh;
  font-size: 6vh;
  font-weight: bold;
  position: absolute;
  z-index: 3;
  top: 4vh;
  left: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  color: var(--brown);
}
.nav-btn:hover {
  color: var(--darkPink);
}
.nav a {
  text-decoration: none;
  color: black;
  font-family: 'Tan Nimbus', serif;
  font-size: 4.5vh;
  margin: 2.5vh;
}
.nav a:hover {
  color: var(--darkPink);
}
.x-btn {
  color: black;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  cursor: pointer;
  font-size: 6vh;
  font-weight: bold;
  position: absolute;
  top: 4vh;
  left: 4vw;
}
.x-btn:hover {
  color: var(--darkPink);
}
.fixed-links-container, .nav-icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  position: fixed;
}
.fixed-links-container {
  right: 3vw;
  bottom: 3vh; 
  height: 35vh;
  width: 5vw;
  z-index: 1;
}
.nav-icons-container {
  position: absolute;
  right: 0;
  bottom: 8vh;
  height: 15vh;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
}
.fixed-links-container a, .nav-icons-container a {
  text-decoration: none;
  color: black;
  font-size: 6vh;
  line-height: 6vh;
}
.register-icon {
  font-weight: bolder;
}
.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 6.5vh;
  width: 15vh; */
  margin-bottom: 5px;
}
.icon-wrapper p {
  font-size: 3vh;
  font-weight: bolder;
  font-family: 'Abhaya Libre', serif;
  line-height: 2vh;
  margin-top: 4px;
  text-align: center;
}
.fixed-links-container a:hover {
  color: var(--darkPink);
}
.nav-icons-container .icon-wrapper {
  width: 15vw;
  overflow: visible;
}
.nav-icons-container .icon-wrapper p {
  font-size: 2.5vh;
  font-weight: bolder;
  color: black;
}
.nav-icons-container .icon-wrapper:hover {
  color: var(--darkPink);
}
.fixed-links-container .reg-btn {
  border: solid;
  color: black;
  border-color: black;
  background-color: var(--brown);
  font-family: 'Abhaya Libre', serif;
  font-size: 2vh;
  font-weight: bolder;
  border-radius: 8px;
  border-width: 3px;
  height: 5vh;
  width: 8.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  align-self: center;
}
.reg-btn:hover {
  color: var(--darkPink);
  border-color: var(--darkPink);
}

@media only screen and (max-width: 1050px) {
  .nav-btn {
    top: 2vh;
    font-size: 5vh;
    line-height: 5vh;
  }
  .x-btn {
      top: 2vh;
      left: 3.5vw;;
      padding: 0;
      margin: 0;
  }
  .nav a {
      color: var(--darkPink);
      font-size: 3vh;
  }
  .nav a:hover {
      text-decoration: underline;
  }
  .fixed-links-container {
    right: 5vw;
  }
  .fixed-links-container a {
    font-size: 4.5vh;
  }

}

@media only screen and (max-width: 480px) {
  .nav-btn {
      top: 0;
      font-size: 4vh;
      line-height: 5vh;
  }
  .nav a {
      color: var(--darkPink);
      font-size: 3vh;
  }
  .nav a:hover {
      text-decoration: underline;
  }
  .nav-icons-container {
    bottom: 10vh;
  }
  .nav-icons-container a {
    height: 5vh;
  }
  .nav-icons-container .icon-wrapper p {
    font-size: 13px;
    color: var(--darkPink);
    margin-top: 0;
  }
  .icon-wrapper p {
    font-size: 10px;
    line-height: 10.5px;
  }
  .icon-wrapper {
    height: 5vh;
    margin: 0;
  }
  .fixed-links-container, .nav-icons-container {
    height: 20vh;
  }
  .fixed-links-container .reg-btn {
    width: 9.5vh;
  }

}

